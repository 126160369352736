import React from 'react'
import { Link } from 'react-router-dom'
import PrimeReact from 'primereact/api';
 
import { Button } from 'primereact/button';

function Navbar() {
  PrimeReact.ripple = true;
  return (
    <div className="p-d-flex p-flex-row p-jc-center p-mt-4 p-mt-xl-6">
      <div className="p-d-flex p-flex-column p-flex-sm-row p-js-center">
        <Link to="/blog" ><Button label="ブログ" className="p-shadow-4 p-mr-3 p-button-secondary p-button-rounded" /></Link>
        <Link to="/portfolio" ><Button label="ポートフォリオ" className="p-shadow-4 p-mt-2 p-mt-sm-0 p-mr-3 p-button-secondary p-button-rounded" /></Link>
      </div>
      <div className="p-d-flex p-flex-column p-flex-sm-row p-js-center">
        <Link to={{ pathname: "https://Github.com/tetymd" }} target="_blank" ><Button label=" GitHub" className="pi pi-github button-github p-shadow-4 p-mr-3 p-button-secondary p-button-rounded" /></Link>
        <Link to={{ pathname: "https://twitter.com/tetymd" }} target="_blank" ><Button label=" Twitter" className="pi pi-twitter button-twitter p-shadow-4 p-mt-2 p-mt-sm-0 p-mr-3 p-button-secondary p-button-rounded" /></Link>
      </div>
    </div>
  )
}

export default Navbar