import React from 'react'
import { Link } from 'react-router-dom'

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

var portfolio = {
  portfolios: [
    {
      title: "ホームページ",
      content: "hellow world.",
      image: "",
      url: ""
    },
    {
      title: "DEVCHA",
      content: "DEVCHAT",
      image: "",
      url: ""
    },
  ]
}

function Portfolio() {
  console.log(portfolio.portfolios[0].title)
  const header_1 = <img className="portfolio-header-image" alt="Card" width="480" height="270" src={`${process.env.PUBLIC_URL}/homepage.png`}/>;
  const header_2 = <img className="portfolio-header-image" alt="Card" width="480" height="270" src={`${process.env.PUBLIC_URL}/devcha.png`}/>;
  const footer = <span>
      <Link to={{ pathname: portfolio.portfolios[0].url }} target="_blank" ><Button label="View" icon="pi pi-eye"  className="p-mr-4" style={{marginRight: '.25em'}}/></Link>
      <Link to={{ pathname: "" }} target="_blank" ><Button label="Github" icon="pi pi-github" className="button-github"/></Link>
  </span>;
  return (
    <div className="portfolio">
      <p className="page-title p-text-center">ポートフォリオ</p>
      <div className="portfolio-list">
        <div className="p-grid p-justify-center p-nogutter">
          <div className="p-col-12 p-md-6 p-lg-4 p-xl-4 p-p-3">
            <Card className="portfolio-card p-shadow-3" title={portfolio.portfolios[0].title} footer={footer} header={header_1}>
              { portfolio.portfolios[0].content }
            </Card>
          </div>
          <div className="p-col-12 p-md-6 p-lg-4 p-xl-4 p-p-3">
            <Card className="portfolio-card p-shadow-3" title="DEVCHA" footer={footer} header={header_2}>
              Content
            </Card>
          </div>
          <div className="p-col-12 p-md-6 p-lg-4 p-xl-4 p-p-3">
            <Card className="portfolio-card p-shadow-3" title={portfolio.portfolios[0].title} footer={footer} header={header_1}>
              { portfolio.portfolios[0].content }
            </Card>
          </div>
          <div className="p-col-12 p-md-6 p-lg-4 p-xl-4 p-p-3">
            <Card className="portfolio-card p-shadow-3" title={portfolio.portfolios[0].title} footer={footer} header={header_1}>
              { portfolio.portfolios[0].content }
            </Card>
          </div>
          <div className="p-col-12 p-md-6 p-lg-4 p-xl-4 p-p-3">
            <Card className="portfolio-card p-shadow-3" title="DEVCHA" footer={footer} header={header_2}>
              Content
            </Card>
          </div>
          <div className="p-col-12 p-md-6 p-lg-4 p-xl-4 p-p-3">
            <Card className="portfolio-card p-shadow-3" title={portfolio.portfolios[0].title} footer={footer} header={header_1}>
              { portfolio.portfolios[0].content }
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portfolio;