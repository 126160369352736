  
import React from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router } from 'react-router-dom'
import './stylesheet/Foundation/base.scss'
import Home from './Home.js'
import Blog from './Blog.js'
import Portfolio from './Portfolio.js'
import NotFound from './NotFound.js'

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
